<!-- 精品课堂-详情 -->
<template>
    <div class="wrapper classroom-wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="4" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container mobile-bread flex x-left">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb class="flex x-left">
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/course/index?type=2' }">精品课堂</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item style="max-width:960px;" class="row-1">{{courseDetail.title}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="classroom-detail container">
                <div class="flex classroom-detail-content">
                    <div class="classromm-left">
                        <div class="video-image">
                            <img :src="courseDetail.default_pic_url | urlFilter(750)" alt="">
                        </div>
                    </div>
                    <div class="classromm-right flex flex-column">
                        <div class="flex-item">
                            <div class="classroom-title row-2">{{courseDetail.title}}</div>
                            <div class="buy-num flex x-between mt15">
                                <div class="color999 font14">{{courseDetail.sale_num || 0}}人购买</div>
                                <div v-if="courseDetail.is_collect" class="collect color999" style="cursor: pointer;">
                                    <span class="med med-shoucang1"></span>
                                    <span class="ml5 font14">已收藏</span>
                                </div>
                                <div @click="handleCollectEvent" v-else class="collect color999" style="cursor: pointer;">
                                    <span class="med med-shoucang"></span>
                                    <span class="ml5 font14">收藏</span>
                                </div>
                            </div>
                            <div class="describe color333 font16 t-l mt15 row-5">{{courseDetail.keyword}}</div>
                        </div>
                        <!-- 
                            power_type 是否为免费 1免费 2收费
                            is_sale 是否购买 1购买 0未购买
                         -->
                        <div v-if="Object.keys(courseDetail).length" class="price flex x-between y-center">
                            <!-- 收费状态并且未付费 -->
                            <div v-if="courseDetail.power_type == 2 && courseDetail.is_sale != 1">￥{{courseDetail.price}}</div>
                            <!-- 免费并且未付费 -->
                            <div v-if="courseDetail.power_type == 1 && courseDetail.is_sale != 1">免费</div>
                            <!-- 判断是否为收费状态并且未购买 -->
                            <div v-if="courseDetail.is_sale != 1 && courseDetail.power_type == 2" class="buy-btn" @click="toPay">立即购买</div>
                            <!-- 目录中存在视频并且已付费或者免费 -->
                            <div  v-if="dirList.length && (courseDetail.is_sale == 1 || courseDetail.power_type == 1)" style="width:100%;" class="t-r">
                                <router-link :to="`/course/classroom-play?id=${dirList[0].id}&course_id=${courseDetail.id}`" class="catalogue-right">
                                    立即观看
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- table切换 -->
            <div class="classroom-table container t-l">
                <a-tabs v-model="activeKey" :animated="false" @change="handleTabChangeEvent">
                    <a-tab-pane key="1" tab="课程介绍" class="mt10">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="introduce" v-html="courseDetail.intro" style="min-height:200px;">
                            </div>
                        </a-spin>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="课程目录">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="catalogue" style="min-height:200px;">
                                <div v-for="(item,index) in dirList" :key="index" class="catalogue-item flex x-between y-center">
                                    <div class="catalogue-left flex-item">
                                        <div class="row-2 fs16 strong">{{item.title}}</div>
                                        <div class="mt5">
                                            <span class="fs14 strong">{{item.real_name}}</span>
                                            <span class="ml10">{{item.org_cnname}}</span>
                                        </div>
                                    </div>
                                    <!-- 已交费或者免费 -->
                                    <router-link :to="`/course/classroom-play?id=${item.id}&course_id=${courseDetail.id}`" v-if="courseDetail.is_sale == 1 || courseDetail.power_type == 1" class="catalogue-right">
                                        立即观看
                                    </router-link>
                                </div>
                                <div class="t-c mt30" v-if="dirCount > 0">
                                    <a-pagination :total="dirCount" :current="dirParams.page" :page-size="dirParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                                </div>
                            </div>
                        </a-spin>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="留言评论">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="container" style="min-height:200px; max-width:100%;">
                                <comment-list :list="commentList" :commentCount="commentCount" :pageSize="commentParams.limit" @pageEvent="commentPageChangeEvent($event)" @handleTextChange="handleTextChange($event)"></comment-list>
                            </div>
                        </a-spin>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <Footer></Footer>
        <buy-modal ref="buyModal"></buy-modal>
    </div>
</template>

<script>
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import commentList from '@/components/comment-list.vue';
import { videoLogo, mediaNavList } from '@/config/const';
import { setWeixinShare } from "@/utils/wechatShare";
import buyModal from '@/components/buy-modal.vue';
export default {
    components: {
        Header,
        Footer,
        buyModal,
        commentList
    },
    name: "classroomDetails",
    data() {
        return {
            navList: mediaNavList, //顶部菜单
            logoUrl: videoLogo,
            activeKey: '1',
            loading: false,

            commentList: [], //视频评论
            commentCount: 1, //评论总数
            commentParams: {
                page: 1,
                limit: 5,
            },

            dirList : [],   // 目录列表
            dirCount: 0,    // 目录数量
            dirParams: {
                page: 1,
                limit:5,
            },

            courseDetail: {}
        }
    },
    methods: {
        toPay() {
            this.$refs.buyModal.show(this.courseDetail,2);
        },

        // 收藏课程
        handleCollectEvent(){
            this.request.get('addOnlineCollect',{type:2,type_id:this.$route.query.id}).then(res => {
                this.courseDetail.is_collect = !this.courseDetail.is_collect;
            })
        },

        // 选项卡切换
        handleTabChangeEvent(key) {
            // 留言评论
            if (key == 3) {
                this.getCommentList();
            } else if(key == 2){
                this.getCourseListDir();
            }
        },

        // 获取课程目录
        getCourseListDir(){
            this.loading = true
            this.dirParams.course_id = this.$route.query.id
            this.request.get('getCourseListDir', this.dirParams).then(res => {
                this.dirCount = parseInt(res.data.count);
                this.dirList = res.data.list;
                this.loading = false;
            })
        },

        // 切换视频目录
        handlePageChangeEvent(e){
            this.dirParams.page = e
            this.getCourseListDir()
        },

        // 视频评论
        getCommentList() {
            this.loading = true
            this.commentParams.type = 7;
            this.commentParams.type_id = this.$route.query.id;
            this.request.get('getOnlineCommentList', this.commentParams).then(res => {
                this.commentCount = parseInt(res.data.count)
                this.commentList = res.data.list
                this.loading = false
            })
        },
        // 视频评论切换页码
        commentPageChangeEvent(e) {
            this.commentParams.page = e
            this.getCommentList()
        },
        // 发表评论
        handleTextChange(e) {
            let data = {
                type:7,
                content:e,
                type_id:this.$route.query.id
            }
            this.request.get('addOnlineComment', data).then(res => {
                this.commentParams.page = 1;
                this.getCommentList()
            })
        },
        // 获取视频详情
        getPremiumVideoInfo() {
            this.request.get('getCourseInfo', { course_id: this.$route.query.id }).then((res) => {
                this.courseDetail = res.data;
                 // 设置分享
                let share_title = res.data.share_title || res.data.title;
                let share_desc = res.data.share_desc || res.data.intro.substr(0,30);
                this.activeKey = '2';//默认展示目录
                setWeixinShare(share_title,share_desc,res.data.default_pic_url)

            })
        },
    },
    created() {
        this.getPremiumVideoInfo();
        this.getCourseListDir();
    }
}
</script>

<style scoped lang="less">
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
    .classroom-detail {
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        .classromm-left {
            width: 600px;
            .video-image {
                width: 100%;
                background: #f1f1f1;
                position: relative;
                padding-bottom: 56.25%;
                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                }
            }
        }
        .classromm-right {
            flex: 1;
            padding-left: 20px;
            box-sizing: border-box;
            .classroom-title {
                font-size: 22px;
                font-weight: bold;
                text-align: left;
                white-space: pre-wrap;
                line-height: 33px;
            }
            .describe {
                font-size: 16px;
                line-height: 24px;
            }
            .price {
                & div:nth-child(1) {
                    font-size: 30px;
                    color: #FFA45D;
                    font-weight: bold;
                }
                .buy-btn {
                    background: linear-gradient(to right, #f5bf58, #f2873b);
                    border-radius: 20px;
                    height: 40px;
                    line-height: 40px;
                    padding:0 25px;
                    text-align: center;
                    font-size: 18px;
                    cursor: pointer;
                    color: #FFFFFF;
                }
            }
        }
    }
    .classroom-table {
        padding: 20px;
        box-sizing: border-box;
        /deep/.ant-tabs-bar {
            border-bottom: 0;
            border-top: 1px solid #d8d8d8;
        }
        /deep/.ant-tabs-nav {
            float: left;
        }
        /deep/.ant-tabs-ink-bar {
            bottom: 0;
            top: 0;
            background: #1e51c9;
        }
        /deep/.ant-tabs-tab {
            font-size: 20px;
            color: #333333;
            padding: 10px 0 0;
        }
        /deep/.ant-tabs-tab-active {
            color: #1e51c9;
        }
        .catalogue {
            .catalogue-item {
                width: 100%;
                padding: 15px;
                box-sizing: border-box;
                box-shadow: 0px 0px 4px 0px rgba(30, 81, 201, 0.15);
                border: 1px solid #F9F9F9;
                border-radius: 5px;
                margin-bottom: 15px;
            }
        }
    }
    .catalogue-right {
        background: linear-gradient(to right, #5f96e6, #1e51c9);
        border-radius: 20px;
        padding: 10px 20px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: #FFFFFF;
        line-height: 1;
        white-space: nowrap;
    }
}

@media screen and (max-width: 768px) {
    .classroom-detail {
        padding: 0 !important;
        .classroom-detail-content {
            display: block;
        }
        .classromm-left {
            width: 100% !important;
        }
        .classromm-right {
            padding-left: 0 !important;
            margin-top:10px;
            padding:10px !important;
            .price {
                margin-top: 20px !important;
                .catalogue-right{
                    width:100%;
                    display: block;
                    padding:12px 20px;
                }
            }
        }
    }
    .mobile-bread {
        display: none;
    }
    .classroom-table {
        width: 100%;
        padding: 10px !important;
        /deep/.ant-tabs-tab {
            font-size: 15px !important;
            margin: 0;
            &.ant-tabs-tab-active {
                font-weight: bold;
            }
        }
        /deep/.ant-tabs-nav {
            width: 100%;
            >div {
                display: flex;
                justify-content: space-around;
            }
        }
        /deep/.ant-tabs-ink-bar {
            display: none !important;
        }
        .catalogue-item{
            padding:10px!important;
        }
         .catalogue-right {
            line-height: 1;
            padding:7px 15px!important;
        }
    }
}
</style>
